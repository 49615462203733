.linkElement {
  color: #007CC7;
  font-size: 3rem;
}

.socialLinkWrapper {
  position: relative;
  display: flex;
  padding: 0 25px 0 25px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: abel;
}

@media only screen and (max-width: 800px) {
  .socialLinkWrapper {
    position: relative;
    display: flex;
    padding: 0 10px 0 10px;
    margin:15px;
    text-shadow: 1px 1px black;
    font-family: abel;
  }
}

.linkElementTitle {
  font-family: abel;
}
